import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="bg-white text-gray-700 flex flex-col min-h-screen">
      <a
        className="mt-10 -translate-x-full focus:translate-x-10 inline-flex items-center text-lg px-4 py-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 uppercase z-50 absolute top-0 "
        href="#main"
      >
        Skip to content
      </a>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main id="main" className="flex-grow">
        {/* <div className="max-w-7xl mx-auto pb-10 px-5">{children}</div> */}
        {children}
      </main>
      <Footer />
      {/* <footer className="">
        <div className="container p-5 flex flex-col md:flex-row flex-wrap justify-between mx-auto">
          <div className="text-sm">
            Copyright &copy; {new Date().getFullYear()} <span>Qwarkle</span>
          </div>
        </div>
      </footer> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
