import React from "react"
import { Link } from "gatsby"
import logoImg from "../svg/logo-light.svg"
const footerNavigation = {
  solutions: [
    { name: "Knowledge Retention", to: "/solutions" },
    { name: "Onboarding/Training", to: "/solutions" },
    { name: "Video Meetings", to: "/solutions" },
    { name: "Collaboration", to: "/solutions" },
  ],
  company: [
    { name: "EKRR App", to: "/application" },
    { name: "Solutions", to: "/solutions" },
    { name: "Pricing", to: "/pricing" },
    { name: "Contact", to: "/contact" },
  ],
  legal: [
    { name: "Privacy Policy", to: "/privacy" },
    { name: "Terms", to: "#!" },
    { name: "Accessibility", to: "/accessibility" },
  ],
  application: [
    { name: "Login", href: "https://app.qwarkle.com/login" },
    { name: "Register", href: "https://app.qwarkle.com/register" },
  ],
}
export default function Footer() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img className="h-9 opacity-75" src={logoImg} alt="Qwarkle Logo" />
            <p className="text-gray-400 text-base">
              A powerful, user-friendly interface for collaboration, building,
              and updating your institutional knowledge data.
            </p>
            <div className="flex space-x-6"></div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.solutions.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className="text-base text-gray-400 hover:text-gray-300"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.company.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className="text-base text-gray-400 hover:text-gray-300"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                  Application
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.application.map(item => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-400 hover:text-gray-300"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-200 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.to}
                        className="text-base text-gray-400 hover:text-gray-300"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-700 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} Qwarkle. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
